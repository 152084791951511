import {GET_APPLICATION_TYPES} from "@/store/modules/base/action-types";

export default {
    computed: {
        types(){
            return this.$store.state.base.applicationTypes;
        },
    },
    created(){
        if (!this.types){
            this.$store.dispatch('base/' + GET_APPLICATION_TYPES);
        }
    },
};