<template>
    <div >

        <organizer-dashboard-navigation />

        <create-artistic-vacancy />

        <div class="has-text-centered mt-5" v-if="vacancies && !vacancies.length">
            <h4 class="title is-4 has-text-grey-dark">{{$t('You have no published vacancies')}}</h4>
        </div>


        <!--VACANCIES LIST-->
      <div v-if="$store.state.auth.user.is_organizer">

        <div v-for="vacancy in vacancies"
             class="card mb-3"
             :key="'vacancy-' + vacancy.id">
          <div class="card-content">
            <div class="block">
              {{vacancy.title || $t('unpublished vacancy')}}
            </div>
            <div class="block">
              {{$t('Applications')}}: {{ vacancy.applications_count }}
            </div>
          </div>

          <div class="card-footer">
            <a @click.prevent="editVacancy(vacancy)" class="card-footer-item">{{$t('edit')}}</a>
            <a @click.prevent="handleCloseVacancy(vacancy.id)" :loading="formBusy" class="card-footer-item">{{$t('close')}}</a>
          </div>

        </div>

          <laravel-pagination :pagination-meta="paginationMeta" @change="getVacancies" />


      </div>


    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import formHelperMixin from "@/mixins/formHelperMixin";
    import {
        CLOSE_VACANCY,
        GET_ARTISTIC_VACANCIES
    } from "@/store/modules/artisticVacancies/actions-types";
    import CreateArtisticVacancy from "@/components/Organizer/CreateArtisticVacancy";
    import OrganizerDashboardNavigation from "@/components/Organizer/DashboardNavigation";
    import confirmationMessageMixin from "@/mixins/confirmationMessageMixin";
    import LaravelPagination from "@/components/LaravelPagination";

    export default {
        name: "OrganizerVacancies",
        title(){ return this.$t('Organizer vacancies');},
        components: {OrganizerDashboardNavigation, CreateArtisticVacancy, LaravelPagination, },
        data(){
            return {
                paginationMeta: {},
            };
        },
        methods: {
            ...mapActions('artisticVacancies', [
                GET_ARTISTIC_VACANCIES,
                CLOSE_VACANCY,
            ]),
            editVacancy(vacancy){
                this.$router.push({
                    name: 'editVacancy',
                    params: {
                        id: vacancy.id,
                        vacancy: vacancy,
                    }
                });
            },
            async handleCloseVacancy(vacancyId){
                const {result} = await this.$_p_confirmAction(this.$t('Close the vacancy?'));
                if (result){
                    this[CLOSE_VACANCY](vacancyId);
                    //TODO: update vacancies counter
                }
            },
            getVacancies(page = 1){
                this.$Progress.start();
                this[GET_ARTISTIC_VACANCIES]({page})
                    .then((res) => {
                        this.$Progress.finish();
                        this.paginationMeta = res.data.meta;
                    })
                    .catch(() => {this.$Progress.fail();});
            }
        },
        computed: {
            ...mapState('artisticVacancies',[
                'vacancies',
                'editedVacancy'
            ]),
        },
        created(){
            this.getVacancies();
        },
        mixins: [formHelperMixin, confirmationMessageMixin],
    };
</script>

<style scoped>

</style>