<template>
    <div class="mb-3">
        <template v-if="user.organizer_profile">
            <!--CREATE VACANCY BUTTON-->
            <b-button v-if="user.organizer_profile && user.organizer_profile.vacancies_left"
                      expanded
                      @click="createArtisticVacancy"
                      :disabled="!(user.organizer_profile && user.organizer_profile.vacancies_left)">
                {{$t('create vacancy')}} <b-tag rounded v-if="!subscriptionDisabled">{{user.organizer_profile.vacancies_left}} / {{ createVacanciesLimitAmount }}</b-tag>
            </b-button>
            <span v-else>
                <b-button tag="router-link" :to="{name: 'subscription'}" expanded type="is-primary">{{ $t('go to subscription page')}}</b-button>
            </span>
        </template>
        <template v-else>
            <create-organize-profile-form>
                <template #title>
                    {{ $t("Please fill out the Organizer's profile to be able to create vacancies") }}
                </template>
            </create-organize-profile-form>
        </template>

    </div>
</template>

<script>
import applicationTypesMixin from "@/mixins/applicationTypesMixin";
import subscriptionDisabledMixin from "@/mixins/subscriptionDisabledMixin";
import CreateOrganizeProfileForm from "@/components/Forms/CreateOrganizerProfileForm";
import {SET_USER} from "@/store/modules/auth/mutation-types";
import {mapMutations} from "vuex";
import {CREATE_BLANK_ARTISTIC_VACANCY} from "../../store/modules/artisticVacancies/actions-types";

export default {
    name: "CreateArtisticVacancy",
    components: {CreateOrganizeProfileForm},
    data(){
        return {

        };
    },
    methods: {
        ...mapMutations('auth', [
            SET_USER,
        ]),
        createArtisticVacancy(){
          this.$Progress.start();

          this.$store.dispatch('artisticVacancies/' + CREATE_BLANK_ARTISTIC_VACANCY)
              .then((res) => {
                this.$Progress.finish();
                this.$router.push({name: 'editVacancy', params: {id: res.data.id}});
              }).catch(() => { this.$Progress.fail(); });
        },

    },
    computed: {
        user(){
            return this.$store.state.auth.user;
        },
        createVacanciesLimitAmount(){
            let planLimit = this.user.subscription.plan.limits.find(l => this.types.plan_limit_create_vacancy === l.name );
            return planLimit ? planLimit.value : null;
        },
    },
    mixins:[applicationTypesMixin, subscriptionDisabledMixin],
};
</script>

<style scoped>

</style>